export const MainVideo = ({ suitability }: { suitability?: string }) => {
  const baseCdnUrl =
    "https://static.bundle.app/ad-assets/samsung-review/videos/";
  const randomNum = Math.floor(Math.random() * 3);
  const allVidoSrc =
    randomNum === 0 ? "white-collar" : randomNum === 1 ? "gamer" : "woman";

  const videoSrc =
    suitability === "white-collar"
      ? "white-collar"
      : suitability === "gamer"
      ? "gamer"
      : suitability === "woman"
      ? "woman"
      : allVidoSrc;

  return (
    <div className="mb-6 md:px-[18px]">
      <video
        className="w-full md:rounded-[20px]"
        autoPlay
        muted
        playsInline
        loop
      >
        <source src={`${baseCdnUrl}${videoSrc}.mp4`} type="video/mp4" />
      </video>
    </div>
  );
};
