import { SVGProps } from "react";

export const TranslateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={15}
    fill="none"
    {...props}
  >
    <path
      fill="#EE2A34"
      d="M.641.983v7.6h7.5v-7.6h-7.5Zm3.282 1.425h.937v.95h1.875v.95h-.963c-.074.658-.305 1.192-.626 1.61.482.209 1.063.29 1.589.29v.95c-.73 0-1.598-.113-2.344-.565-.746.452-1.613.565-2.343.565v-.95c.525 0 1.107-.081 1.589-.29-.321-.418-.552-.952-.626-1.61h-.963v-.95h1.875v-.95Zm.032 1.9c.061.475.217.814.436 1.07.22-.256.375-.595.437-1.07h-.873Zm5.124 2.375v.95h3.75v5.7H7.204v-3.8h-.938v4.75h7.5v-7.6H9.08Zm.469 1.425-1.875 4.75h.937l.375-.95h2.062l.375.95h.938l-1.875-4.75h-.937Zm.468 1.187.657 1.663H9.36l.656-1.663Z"
    />
  </svg>
);
