export const Button = ({
  handleClick,
  text,
  cx,
}: {
  handleClick: () => void;
  text: string;
  cx?: string;
}) => {
  return (
    <div
      className={`min-[409px]:max-w-[373px] mx-auto max-[409px]:px-[18px] flex items-center justify-center ${cx}`}
    >
      <button
        onClick={handleClick}
        className="border w-full border-light-text-tertiary p-[18px] rounded-full"
      >
        <p className="text-light-text-tertiary text-sm font-bold">{text}</p>
      </button>
    </div>
  );
};
