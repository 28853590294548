import { useEffect, useState } from "react";
import { IsTranslatedComp } from "./IsTranslated";
import { Translation } from "../../../types/comment";

export const Content = ({
  senderName,
  translation,
  parsedContent,
}: {
  senderName: string;
  translation: Translation;
  parsedContent: string;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [showTranslated, setShowTranslated] = useState<boolean>(true);

  const [pageWidth, setPageWidth] = useState<number>(window.innerWidth);

  const [sliceNumber, setSliceNumber] = useState<number>(150);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const content = translation?.status
    ? showTranslated
      ? translation?.content ?? ""
      : parsedContent
    : parsedContent;

  useEffect(() => {
    if (pageWidth < 640) {
      setSliceNumber(150);
    } else if (pageWidth < 768) {
      setSliceNumber(200);
    } else if (pageWidth < 1024) {
      setSliceNumber(250);
    } else if (pageWidth < 1280) {
      setSliceNumber(300);
    } else {
      setSliceNumber(350);
    }
  }, [pageWidth]);

  return (
    <div className="flex flex-col gap-y-4 w-full">
      <h4 className="text-xs font-bold text-light-text-tertiary">
        Kullanıcı Adı: {senderName}
      </h4>
      <p className="text-[22px] font-bold text-light-text-secondary">
        "{!isOpen ? content.slice(0, sliceNumber) : content + '"'}
        {content.length <= sliceNumber && '"'} &nbsp;
        {content?.length > sliceNumber && (
          <span
            onClick={() => setIsOpen(!isOpen)}
            className="text-light-text-tertiary text-xs cursor-pointer"
          >
            {!isOpen ? "devamını gör" : "kısa metnine dön"}
          </span>
        )}
      </p>
      {translation?.status && (
        <IsTranslatedComp
          setShowTranslated={setShowTranslated}
          showTranslated={showTranslated}
          original_language={translation?.original_language_in_turkish}
        />
      )}
    </div>
  );
};
