import { TranslateIcon } from "../../../assets/TranslateIcon";

export const IsTranslatedComp = ({
  showTranslated,
  setShowTranslated,
  original_language,
}: {
  showTranslated: boolean;
  setShowTranslated: (value: boolean) => void;
  original_language?: string;
}) => {
  return (
    <div className="flex items-center gap-x-2">
      <TranslateIcon />
      <p className="text-[10px] text-black font-bold">
        <span className="">BUNDLE</span>{" "}
        <span className="!text-bundle-red">AI</span> <span>ÇEVİRDİ</span>
      </p>
      <span className="bg-[#A0A8AB] w-[3px] h-[3px] rounded-full"></span>
      <button
        onClick={() => setShowTranslated(!showTranslated)}
        className="font-barlow text-xs"
      >
        <span className="text-[#0076FF] font-bold">
          {showTranslated ? "Orijinalini Gör" : "Çevirisini Gör"}
        </span>
        {showTranslated && (
          <span className="text-light-stroke font-semibold">
            {" "}
            ({original_language})
          </span>
        )}
      </button>
    </div>
  );
};
