import { FC, SVGProps } from "react";

interface SamsungLogoProps extends SVGProps<SVGSVGElement> {
  isDarkMode?: boolean;
}

export const SamsungLogo: FC<SamsungLogoProps> = ({
  isDarkMode = false,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={71}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill={isDarkMode ? "#fff" : "#000"}
      d="M58.667 8.783h-.054L56.29.43h-3.746v10.53h2.482l-.14-8.641h.054l2.494 8.641h3.595V.43h-2.498l.136 8.353ZM11.002.429 9.133 11.071h2.728l1.382-9.66h.057l1.346 9.66h2.711L15.497.43h-4.495ZM26.258.43l-1.246 8.137h-.057L23.715.43h-4.118l-.224 10.641h2.525l.063-9.57h.057l1.686 9.57h2.56l1.687-9.566.056-.003.066 9.57H30.6L30.376.43h-4.118ZM2.88 3.167a1.43 1.43 0 0 1-.01-.556c.072-.341.29-.716.923-.716.592 0 .937.386.937.964v.657h2.52v-.743C7.25.452 5.274.09 3.844.09 2.05.09.584.718.314 2.454c-.073.466-.082.894.025 1.432.439 2.175 4.025 2.805 4.545 4.18.098.257.066.59.018.79-.081.35-.308.711-.982.711-.63 0-1.015-.38-1.015-.961l-.002-1.033H.196l-.001.823c0 2.374 1.772 3.087 3.67 3.087 1.828 0 3.327-.652 3.57-2.428.123-.921.032-1.525-.011-1.75-.428-2.23-4.257-2.89-4.545-4.138Zm32.846.025a1.385 1.385 0 0 1-.012-.546c.07-.34.287-.71.913-.71.585 0 .929.38.929.95v.653h2.494v-.736C40.049.51 38.092.149 36.68.149c-1.779 0-3.231.614-3.495 2.34-.074.463-.086.888.023 1.419.434 2.152 3.985 2.777 4.501 4.138.095.256.066.583.02.782-.082.351-.31.702-.974.702-.627 0-1.005-.375-1.005-.95l-.002-1.024h-2.683v.815c0 2.35 1.757 3.055 3.636 3.055 1.806 0 3.293-.646 3.53-2.404.123-.91.033-1.508-.01-1.732-.423-2.206-4.216-2.861-4.496-4.098Zm11.449 5.107a2.23 2.23 0 0 1-.024.406c-.05.265-.266.766-.968.766-.7 0-.918-.501-.968-.766a2.316 2.316 0 0 1-.026-.406V.43h-2.551v7.626c-.004.198.014.597.023.703.174 1.984 1.665 2.625 3.522 2.625 1.854 0 3.345-.64 3.524-2.625a10.7 10.7 0 0 0 .025-.703V.428h-2.558V8.3Zm20.088-3.21v1.558h1.034V8.19c.003.134-.002.282-.024.404-.045.287-.303.77-1.03.77-.73 0-.987-.484-1.034-.77a2.361 2.361 0 0 1-.023-.404V3.321c0-.173.01-.362.041-.5.054-.261.271-.772 1.005-.772.773 0 .963.54 1.01.773.03.147.031.402.031.4v.6h2.544v-.355s.01-.364-.02-.696C70.609.77 69.04.14 67.252.14s-3.32.635-3.542 2.631a7.16 7.16 0 0 0-.044.696l-.001 4.477c0 .2.007.349.035.703.166 1.938 1.763 2.632 3.55 2.632 1.788 0 3.386-.694 3.553-2.632.028-.355.032-.503.032-.703V5.088h-3.573Z"
    />
  </svg>
);
