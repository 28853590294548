import { FC, SVGProps } from "react";

interface HeartIconProps extends SVGProps<SVGSVGElement> {
  isLiked?: boolean;
  isDarkMode?: boolean;
}

export const HeartIcon: FC<HeartIconProps> = ({
  isLiked,
  isDarkMode = true,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill={isLiked ? "#FF2D55" : "none"}
      stroke={isLiked ? "none" : isDarkMode ? "#783BF9" : "#196788"}
      d="M11.367 19.69a.916.916 0 0 1-.65-.266l-7.118-7.127a4.818 4.818 0 0 1 0-6.779 4.8 4.8 0 0 1 6.778 0l.99.99.989-.99a4.8 4.8 0 0 1 6.778 0 4.818 4.818 0 0 1 0 6.779l-7.117 7.127a.916.916 0 0 1-.65.265Z"
    />
  </svg>
);
