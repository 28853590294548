import { useRef, useEffect } from "react";

function useDocumentTitle(
  title: string,
  description: string,
  prevailOnUnmount: boolean = false
) {
  const defaultTitle = useRef(document.title);

  const metaTag = useRef<HTMLMetaElement | null>(null);
  const defaultContent = useRef<string | null>("");

  useEffect(() => {
    metaTag.current = document.querySelector(`meta[name="description"]`);
    if (metaTag.current) {
      defaultContent.current = metaTag.current.getAttribute("content");
      metaTag.current.setAttribute("content", title);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.name = "description";
      newMetaTag.content = title;
      document.head.appendChild(newMetaTag);
      metaTag.current = newMetaTag;
    }
    document.title = title;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        if (metaTag.current) {
          if (defaultContent.current) {
            metaTag.current.setAttribute("content", defaultContent.current);
          } else {
            metaTag.current.remove();
          }
        }
        document.title = defaultTitle.current;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}

export default useDocumentTitle;
