import { Property } from "../../types/property";
import { Title } from "../common/Title";
import { Proporty } from "./Proporty";

const Proporties = ({ suitability }: { suitability?: string }) => {
  const datas: Property[] = [
    {
      name: "Flex Cam",
      content:
        "Cihazın ekranını belirli bir açıyla katlayarak fotoğraf ve video çekimini daha esnek hale getiren bir özelliktir. Telefon yarı açık haldeyken, Flex Cam ile eller serbest çekim yapabilir, ekranı farklı açılarda kullanarak yaratıcı fotoğraflar ve videolar çekebilirsiniz.",
    },
    {
      name: "CTS",
      content:
        "Circle to Search ile herhangi bir uygulamada ekrandaki tüm öğeler için tek hareketle yapay zeka tabanlı akıllı arama yapabilirsiniz. 120'den fazla ülke ve bölgede desteklenmektedir.",
    },
    {
      name: "Chat Assist",
      content:
        "Chat Asistanı ile S24 serisi cihazlarda gerçek zamanlı dil engeline takılmadan sesli aramalar ve sohbetler ile düşüncelerinizi kolayca farklı dillerde aktarabilirsiniz.",
    },
    {
      name: "Sözlü Çevirmen",
      content:
        "Kullanıcıların farklı dillerde anlık konuşmaları çevirmesine olanak tanıyan bir özelliktir. Bu özellik, gerçek zamanlı olarak iki dil arasında çeviri yaparak kullanıcıların yüz yüze ya da telefon üzerinden yapılan konuşmalarda dil engelini aşmalarını sağlar.",
    },
    {
      name: "Gaming Performance",
      content:
        "Güçlü işlemciler, yüksek yenileme hızına sahip geniş ekranlar ve gelişmiş soğutma sistemleriyle oyun performansında üst düzey bir deneyim sunar. Çoklu görev yapma yetenekleri, büyük iç ekranları sayesinde sürükleyici bir oyun deneyimi sağlar ve aynı anda oyun oynarken sohbet veya video izleme gibi diğer işlemleri de yapmanıza olanak tanır.",
    },
  ];

  const suitabilityMapping: { [key: string]: string[] } = {
    "white-collar": ["Flex Cam", "CTS", "Chat Assist", "Sözlü Çevirmen"],
    gamer: ["Gaming Performance", "CTS", "Chat Assist"],
    woman: ["Flex Cam", "Chat Assist", "CTS", "Sözlü Çevirmen"],
  };

  const filteredDatas = suitability
    ? datas.filter((data) =>
        suitabilityMapping[suitability]?.includes(data.name)
      )
    : datas;

  return (
    <div className="flex px-[18px] flex-col gap-y-[18px] my-[28px] w-full">
      <Title text="SAMSUNG GALAXY Z SERİSİ ÖZELLİKLERİ" />
      {filteredDatas?.map((data, index) => (
        <Proporty key={index} propoerty={data} />
      ))}
    </div>
  );
};

export default Proporties;
