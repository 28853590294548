import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

interface Item {
  title: string;
  icon: string;
  handleClick: () => void;
}

const DropdownItem = (item: Item) => {
  const { title, icon, handleClick } = item;
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        handleClick();
      }}
      className="px-4 h-[45px] cursor-pointer flex items-center w-[138px] justify-between"
    >
      <p className="font-barlow text-sm font-medium text-white">{title}</p>
      <img src={icon} alt="icon" className="w-[18px] h-[18px]" />
    </div>
  );
};

export const Dropdown = ({
  setIsDropdownOpen,
}: {
  setIsDropdownOpen: (value: boolean) => void;
}) => {
  const location = useLocation();
  const { hash, pathname, search } = location;

  const url = `${window.location.origin}${pathname}${search}${hash}`;

  const pageTitle = document.title;

  const dropdownItems: Item[] = [
    {
      title: "Facebook",
      icon: "/icons/facebook.svg",
      handleClick: () => {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${url}`,
          "_blank",
          "width=600,height=400"
        );
      },
    },
    {
      title: "X",
      icon: "/icons/x.svg",
      handleClick: () => {
        window.open(
          `https://twitter.com/intent/tweet?url=${url}&text=${pageTitle}`,
          "_blank",
          "width=600,height=400"
        );
      },
    },
    {
      title: "WhatsApp",
      icon: "/icons/whatsapp.svg",
      handleClick: () => {
        window.open(
          `https://api.whatsapp.com/send/?text=${url}`,
          "_blank",
          "width=600,height=400"
        );
      },
    },
    {
      title: "Kopyala",
      icon: "/icons/copy.svg",
      handleClick: () => {
        navigator.clipboard.writeText(url);
        setIsDropdownOpen(false);
      },
    },
  ];

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (event: any) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={dropdownRef}
      className="bg-dropdown bg-opacity-70 backdrop-blur-[20px] rounded-xl flex flex-col absolute top-[44px] z-50 right-0"
    >
      {dropdownItems.map((item) => (
        <DropdownItem key={item.title} {...item} />
      ))}
    </div>
  );
};
