import { FC, SVGProps } from "react";

interface ShareIconProps extends SVGProps<SVGSVGElement> {
  isDarkMode?: boolean;
}

export const ShareIcon: FC<ShareIconProps> = ({
  isDarkMode = false,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={15}
    fill="none"
    {...props}
  >
    <path
      stroke={isDarkMode ? "#783BF9" : "#196788"}
      strokeLinejoin="round"
      strokeWidth={1.374}
      d="M17.771 7.29 8.977 1.467v3.297c-7.695 1.65-7.695 9.344-7.695 9.344S4.58 9.71 8.977 10.259v3.408l8.794-6.376Z"
    />
  </svg>
);
