import { useEffect, useState } from "react";

import { Property } from "../../types/property";

export const Proporty = ({ propoerty }: { propoerty: Property }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  const [pageWidth, setPageWidth] = useState<number>(window.innerWidth);

  const [sliceNumber, setSliceNumber] = useState<number>(170);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (pageWidth < 640) {
      setSliceNumber(170);
    } else if (pageWidth < 768) {
      setSliceNumber(200);
    } else if (pageWidth < 1024) {
      setSliceNumber(250);
    } else if (pageWidth < 1280) {
      setSliceNumber(300);
    } else {
      setSliceNumber(350);
    }
  }, [pageWidth]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpen(!isOpen);
      }}
      className="bg-light-stroke rounded-2xl p-[18px] flex flex-col w-full"
    >
      <div className="flex items-center justify-between">
        <p className="text-sm font-bold text-white">{propoerty?.name}</p>
        <div className="w-8 h-8 flex items-center bg-[#090808] bg-opacity-40 justify-center rounded-full border border-light-text-fifth border-opacity-60">
          <span
            className={`w-[10px] h-[1px] rounded-full bg-light-text-fifth relative before:transition-all before:content-[''] before:absolute before:top-1/2 before:-translate-y-1/2 before:w-[1px] before:left-1/2 before:bg-light-text-fifth ${
              !isOpen ? "before:h-[10px]" : "before:h-0"
            }`}
          ></span>
        </div>
      </div>
      <div
        className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
          isOpen ? "max-h-[1000px]" : "max-h-0"
        } ${propoerty?.thumbnail ? "mt-[22px]" : ""}`}
      >
        <div className="flex">
          {propoerty?.thumbnail && (
            <img
              src={propoerty?.thumbnail}
              alt="property"
              className="rounded-xl w-full aspect-[8/5]"
            />
          )}
        </div>
        <div className="flex mt-[22px]">
          <p className="text-sm text-white">
            {isShowMore
              ? propoerty?.content
              : propoerty?.content.slice(0, sliceNumber)}
            {propoerty?.content.length > sliceNumber && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setIsShowMore(!isShowMore);
                }}
                className="text-light-text-fifth cursor-pointer"
              >
                {!isShowMore && "..."}
                &nbsp;
                {!isShowMore ? "devamını gör" : "kısa metnine dön"}
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
