import { SVGProps } from "react";

export const BundleAILogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={15}
    fill="none"
    {...props}
  >
    <rect
      width={10.313}
      height={6.307}
      x={0.02}
      y={9.643}
      fill="#C4050C"
      rx={1.783}
      transform="rotate(-45 .02 9.643)"
    />
    <rect
      width={12.555}
      height={6.307}
      fill="#E62C33"
      rx={1.783}
      transform="scale(-1 1) rotate(-45 2.75 26.277)"
    />
  </svg>
);
