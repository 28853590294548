import { useEffect, useState } from "react";

export const NewComment = ({
  addNewMessages,
}: {
  addNewMessages: () => void;
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 180) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  if (!isVisible) return null;
  return (
    <div className="fixed z-50 top-[98px] left-1/2 -translate-x-1/2">
      <button
        onClick={() => addNewMessages()}
        className="bg-new-comment-background rounded-[30px] flex items-center justify-center gap-x-2.5 w-[136px] h-9"
      >
        <span className="text-xs font-medium font-roboto">Yeni Yorumlar</span>
        <img src="/icons/arrow-rotate-right.svg" alt="rotate" />
      </button>
    </div>
  );
};
