import { useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import debounce from "lodash.debounce";

import { API_URL, SOCKET_URL } from "../utils/settings";

const pageSize = 10;

export const useSocket = (suitability?: string) => {
  const [messageHistory, setMessageHistory] = useState<MessageEvent<any>[]>([]);
  const [isAddedNewMessage, setIsAddedNewMessage] = useState<boolean>(false);

  const [comments, setComments] = useState<any[]>([]);

  const [page, setPage] = useState<number>(1);

  const [total, setTotal] = useState<number>(0);

  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  const socketUrl = suitability
    ? `${SOCKET_URL}/${encodeURIComponent(suitability)}`
    : `${SOCKET_URL}/all`;

  const deletedUrl = `${SOCKET_URL}/deleted`;

  const [reconnectAttempts, setReconnectAttempts] = useState<number>(0);

  const { lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl, {
    onOpen: () => setReconnectAttempts(0), // Reset reconnect attempts on successful connection
    onClose: () => handleReconnect(socketUrl),
    onError: () => handleReconnect(socketUrl),
  });

  const { lastMessage: deletedMessage, getWebSocket: getWebSocketDelete } =
    useWebSocket(deletedUrl, {
      onClose: () => handleReconnectforDelete(deletedUrl),
      onError: () => handleReconnectforDelete(deletedUrl),
    });

  const [isLikeRequestPending, setIsLikeRequestPending] =
    useState<boolean>(false);

  const getComments = async (suitability?: string, c_page?: number) => {
    try {
      const url = new URL(API_URL);
      url.searchParams.append(
        "page",
        c_page ? c_page.toString() : page.toString()
      );
      url.searchParams.append("size", pageSize.toString());

      if (suitability) {
        url.searchParams.append("suitability", suitability);
      }

      const response = await fetch(url.toString());
      const data = await response.json();

      setComments((prev) => {
        const updatedComments = prev.concat(data?.items || []);

        const enhancedComments = updatedComments.map((item) => {
          const isLiked = Boolean(localStorage.getItem(`like_${item.uuid}`));
          return { ...item, isLiked, likeCount: item.like_count };
        });

        return enhancedComments;
      });

      setTotal(data?.total_items);
    } catch (error) {
      console.error("Yorumlar getirilirken bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    if (page * pageSize < total) {
      setIsShowMore(true);
    } else {
      setIsShowMore(false);
    }
  }, [page, total]);

  const addNewMessages = () => {
    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage));
      setComments((prev) =>
        [
          {
            ...JSON.parse(lastMessage.data),
            isLiked: false,
          },
        ].concat(prev)
      );
      setIsAddedNewMessage(false);
      window.scrollTo({
        top: 320,
        behavior: "smooth",
      });
    }
  };

  const showMoreComments = () => {
    setPage((prev) => prev + 1);
    getComments(suitability, page + 1);
  };

  useEffect(() => {
    if (lastMessage !== null) {
      setIsAddedNewMessage(true);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (deletedMessage) {
      const deletedData = JSON.parse(deletedMessage.data);
      setComments((prev) =>
        prev.filter((comment) => comment.uuid !== deletedData.uuid)
      );
    }
  }, [deletedMessage]);

  const debouncedLikeRequest = useRef(
    debounce(async (id: string, isLiked: boolean) => {
      try {
        await fetch(`${API_URL}/${id}/like`, { method: "PUT" });
      } catch (error) {
        console.error("Beğenme işlemi başarısız:", error);
      } finally {
        setIsLikeRequestPending(false);
      }
    }, 500)
  ).current;

  const handleLike = (id: string) => {
    if (isLikeRequestPending) return;

    setIsLikeRequestPending(true);

    setComments((prevComments) =>
      prevComments.map((comment) =>
        comment.uuid === id
          ? {
              ...comment,
              isLiked: !comment.isLiked,
              likeCount: comment.isLiked
                ? comment.likeCount - 1
                : comment.likeCount + 1,
            }
          : comment
      )
    );

    const localStorageKey = `like_${id}`;
    const isLiked = localStorage.getItem(localStorageKey);

    if (isLiked) {
      localStorage.removeItem(localStorageKey);
    } else {
      localStorage.setItem(localStorageKey, "true");
    }

    debouncedLikeRequest(id, !isLiked);
  };

  const handleReconnect = (url: string) => {
    setReconnectAttempts((prev) => prev + 1);

    const reconnectDelay = Math.min(2000 * reconnectAttempts, 30000); // Exponential backoff

    setTimeout(() => {
      console.log(`Attempting to reconnect to ${url}`);
      getWebSocket(); // Force a reconnect
    }, reconnectDelay);
  };

  const handleReconnectforDelete = (url: string) => {
    setTimeout(() => {
      console.log(`Attempting to reconnect to ${url}`);
      getWebSocketDelete(); // Force a reconnect
    }, 2000);
  };

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  return {
    connectionStatus,
    messageHistory,
    comments,
    handleLike,
    addNewMessages,
    isAddedNewMessage,
    getComments,
    isShowMore,
    showMoreComments,
  };
};
