import { Comment } from "../../../types/comment";
import { Bottom } from "./Bottom";
import { Content } from "./Content";
import { Header } from "./Header";

const CommentComp = ({
  comment,
  handleLike,
  isShowMore,
}: {
  comment: Comment;
  handleLike: (id: string) => void;
  isShowMore?: boolean;
}) => {
  return (
    <div
      key={comment.uuid}
      className={`bg-light-background border border-solid border-light-stroke p-[18px] flex flex-col gap-y-[28px] w-full rounded-2xl ${
        !isShowMore ? "border" : "border-0"
      }`}
    >
      <Header
        sourceThumbnail={comment?.channel_logo_src}
        sourceName={comment?.channel}
        pubdate={comment?.pubdate}
      />
      <Content
        senderName={comment?.author}
        translation={comment?.translation}
        parsedContent={comment?.content}
      />
      <Bottom
        handleLike={() => handleLike(comment?.uuid)}
        product={comment?.product}
        isLiked={comment?.isLiked}
        likeCount={comment?.likeCount || comment?.like_count}
      />
    </div>
  );
};

export default CommentComp;
