import { SVGProps } from "react";

interface RightArrowProps extends SVGProps<SVGSVGElement> {
  isDarkMode?: boolean;
}

export const RightArrow = ({
  isDarkMode = false,
  ...props
}: RightArrowProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={7}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill={isDarkMode ? "#783BF9" : "#fff"}
      d="M.498 11.322a.937.937 0 0 0 0 1.2.68.68 0 0 0 1.05 0l4.459-5.094a.939.939 0 0 0 .022-1.174L1.942 1.159c-.277-.346-.747-.37-1.05-.053a.937.937 0 0 0-.045 1.2l3.606 4.496-3.955 4.52Z"
    />
  </svg>
);
