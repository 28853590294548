export const Header = ({
  sourceThumbnail,
  sourceName,
  pubdate,
}: {
  sourceThumbnail: string;
  sourceName: string;
  pubdate: string;
}) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "long",
    };
    return date.toLocaleDateString("tr-TR", options);
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleTimeString("tr-TR", options);
  };

  const date = formatDate(pubdate);
  const time = formatTime(pubdate);

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-x-2 flex-1">
        <img
          src={sourceThumbnail}
          alt="source-thumbnail"
          className="w-6 h-6 rounded-full"
        />
        <p className="text-[14px]  text-light-text-secondary font-medium">
          {sourceName}
        </p>
      </div>
      <div className="flex items-center gap-x-1">
        <p className="text-[12px]  text-light-text-quaternary font-semibold">
          {date}
        </p>
        {time !== "00:00" && (
          <>
            <span className="w-[2px] h-[2px] bg-light-text-quaternary" />
            <p className="text-[12px]  text-light-text-quaternary font-semibold">
              {time}
            </p>
          </>
        )}
      </div>
    </div>
  );
};
