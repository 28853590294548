import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/gamer",
    element: <App suitability="gamer" />,
  },
  {
    path: "/woman",
    element: <App suitability="woman" />,
  },
  {
    path: "/white-collar",
    element: <App suitability="white-collar" />,
  },
]);

root.render(<RouterProvider router={router} />);
