import { HeartIcon } from "../../../assets/HeartIcon";

export const Bottom = ({
  product,
  likeCount,
  isLiked,
  handleLike,
}: {
  product: string;
  likeCount: number;
  isLiked?: boolean;
  handleLike: () => void;
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className="border rounded-full flex items-center justify-center px-2.5 py-[2px] border-opacity-50 border-light-text-tertiary">
        <span className="text-xs font-semibold text-light-text-tertiary">
          {product}
        </span>
      </div>
      <div className="flex items-center gap-x-1.5">
        <p className="text-xs font-bold text-light-text-tertiary">
          {likeCount}
        </p>
        <button
          onClick={() => handleLike()}
          className="flex items-center justify-center"
        >
          <HeartIcon isLiked={isLiked} />
        </button>
      </div>
    </div>
  );
};
