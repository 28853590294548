import Comments from "./components/Comments";
import { BundleButton } from "./components/Comments/BundleButton";
import { Button } from "./components/common/Button";
import Header from "./components/Header";
import MainTitle from "./components/MainTitle";
import { MainVideo } from "./components/MainVideo";
import Proporties from "./components/Proporties";
import useDocumentTitle from "./hooks/useDocumentTitle";

function App({ suitability }: { suitability?: string }) {
  const title =
    suitability === "gamer"
      ? "Bir Gamer Neden Galaxy Z Fold6 Kullanır?"
      : suitability === "white-collar"
      ? "BİR BEYAZ YAKA NEDEN Z SERİSİ KULLANIR?"
      : suitability === "woman"
      ? "KADINLAR NEDEN Z SERİSİ KULLANIR?"
      : "NEDEN Z SERİSİ KULLANIR?";
  useDocumentTitle(title, title, true);

  const handleDiscover = () => {
    if (suitability === "woman") {
      window.open(
        "https://www.samsung.com/tr/smartphones/galaxy-z-flip6/",
        "_blank"
      );
    } else {
      window.open(
        "https://www.samsung.com/tr/smartphones/galaxy-z-fold6/",
        "_blank"
      );
    }
  };

  return (
    <div className="min-h-screen w-full bg-white">
      <Header />
      <div className="fixed top-0 h-[88px] left-0 right-0 bg-white z-20"></div>
      <div className="top-[88px] pb-[118px] left-0 right-0 h-fit z-10 absolute overflow-x-hidden">
        <div className="max-w-[800px] mx-auto">
          <MainTitle title={title} />
          <MainVideo suitability={suitability} />
          <Comments suitability={suitability} />
          <Proporties suitability={suitability} />
          <BundleButton suitability={suitability} />
          <Button cx="mt-4" handleClick={handleDiscover} text="Keşfet" />
        </div>
      </div>
    </div>
  );
}

export default App;
