import { useState } from "react";

import { BundleLogo } from "../../assets/BundleLogo";
import { SamsungLogo } from "../../assets/SamsungLogo";
import { ShareIcon } from "../../assets/ShareIcon";
import { Dropdown } from "../common/Dropdown";

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  return (
    <div className="fixed top-[24px] z-30 left-[18px] right-[18px]">
      <div className="max-w-[800px] md:px-[18px] container mx-auto">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 justify-center">
            <BundleLogo />
            <div className="bg-header-split h-2.5 w-[1px]"></div>
            <SamsungLogo />
          </div>
          <button
            onClick={() => {
              setIsDropdownOpen(!isDropdownOpen);
            }}
            className="w-10 relative h-10 flex items-center justify-center rounded-full border border-light-text-tertiary border-opacity-60 bg-white"
          >
            {isDropdownOpen && (
              <Dropdown setIsDropdownOpen={setIsDropdownOpen} />
            )}
            <ShareIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
