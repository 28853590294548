import CommentComp from ".";
import { Button } from "../../common/Button";

export const ShowMoreComment = ({
  showMoreComments,
  show,
}: {
  showMoreComments: () => void;
  show: boolean;
}) => {
  const dummyData = {
    uuid: "1",
    channel: "Ekşi Sözlük",
    channel_logo_src: "https://ekstat.com/img/ilogo_smallv2.png",
    author: "Mehmet A.",
    pubdate: "2023-10-19T14:00:00",
    product: "flip6",
    like_count: 378,
    isLiked: true,
    suitability: ["gamer", "woman", "white-collar"],
    content:
      "Z Flip 6, Z Fold 6'dan daha iyi. Z Fold 6'nın ekranı çok büyük ve kullanışsız. Z Flip 6, Z Fold 6'dan daha iyi. Z Fold 6'nın ekranı çok büyük ve kullanışsız. Z Flip 6, Z Fold 6'dan daha iyi. Z Fold 6'nın ekranı çok büyük ve kullanışsız.",
    translation: {
      status: false,
    },
  };

  if (!show) return null;

  return (
    <div className="relative">
      <CommentComp
        isShowMore={true}
        handleLike={() => {}}
        key={"last"}
        comment={dummyData}
      />
      <div className="absolute rounded-2xl z-10 bg-gradient-to-t from-white to-transparent backdrop-blur-[4px] inset-0"></div>
      <Button
        cx="my-6 absolute z-20 bottom-0"
        handleClick={showMoreComments}
        text="Daha Fazlasını Gör"
      />
    </div>
  );
};
