import { BundleAILogo } from "../../assets/BundleAILogo";
import { RightArrow } from "../../assets/RightArrow";
import {
  BUNDLE_ASK_URL_WHITE_COLLAR_GAMER,
  BUNDLE_ASK_URL_WOMAN,
} from "../../utils/constants";

export const BundleButton = ({ suitability }: { suitability?: string }) => {
  const BUNDLE_ASK_URL = () => {
    if (suitability === "woman") {
      return BUNDLE_ASK_URL_WOMAN;
    } else {
      return BUNDLE_ASK_URL_WHITE_COLLAR_GAMER;
    }
  };
  return (
    <div className="fixed z-40 bottom-0 left-[18px] right-[18px] pt-[10px] pb-8">
      <div className="max-w-[373px] mx-auto">
        <a
          href={BUNDLE_ASK_URL()}
          target="_blank"
          rel="noreferrer"
          className="bg-bundle-background py-[14px] pl-5 pr-4 w-full flex items-center justify-between min-[350px]:gap-x-3 rounded-full border-gradient"
        >
          <div className="flex items-center gap-x-3">
            <div className="flex items-center gap-x-1">
              <BundleAILogo />
              <p className="font-bold text-base">
                <span className="text-white">Bundle</span>
                &nbsp;
                <span className="text-bundle-red">AI</span>
              </p>
            </div>
            <p className="text-sm font-semibold font-barlow text-white">
              Merak ettiklerini sor
            </p>
          </div>
          <div className="flex items-center justify-center min-w-9 min-h-9 rounded-full bg-[#0076FF]">
            <RightArrow />
          </div>
        </a>
      </div>
    </div>
  );
};
