import { FC, SVGProps } from "react";

interface BundleLogoProps extends SVGProps<SVGSVGElement> {
  isDarkMode?: boolean;
}

export const BundleLogo: FC<BundleLogoProps> = ({
  isDarkMode = false,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill={isDarkMode ? "#fff" : "#E62C33"}
      d="M8.084.184h6.957c.47 0 .852.381.852.852v11.5c0 .471-.381.853-.852.853H8.936a.852.852 0 0 1-.852-.852V.184Z"
    />
    <path
      fill={isDarkMode ? "#fff" : "#E62C33"}
      fillOpacity={0.5}
      d="M.32 1.036c0-.47.382-.852.853-.852H8.13v8.803H1.173a.852.852 0 0 1-.852-.852v-7.1Z"
    />
  </svg>
);
